<template>
  <div>
    <div class="card">
      <div class="card-header">My Bookmarked Sensors</div>
      <div class="card-block table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr class="text-center">
              <th>Sensor Name</th>
              <th>Sensor Model</th>
              <th>Gateway Name</th>
              <th>Last Reading</th>
              <th>Last Communication</th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="row in lastReadings" :key="row.lastComms">
            <td>{{ row.sensorName }}</td>
            <td>{{ row.sensorModel }}</td>
            <td>{{ row.gatewayName }}</td>
            <td>{{ `${row.lastReading} ${row.units}` }}</td>
            <td>{{ datetimeConversion(row.lastComms) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row" style="padding-top: 20px">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">Communication status for {{ moment(Date()).format('MMMM YYYY') }}</div>
          <div class="card-block table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Uplink Comm.</th>
                  <th>Downlink Comm.</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="gateway in currentCommUsage" :key="gateway.gateway_id">
                  <td>{{gateway.gateway_name}} </td>
                  <td>{{gateway.downlink}}</td>
                  <td>{{gateway.uplink}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            My Map with active gateways
          </div>
          <div class="card-block">
            <gateway-map />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GatewayMap from '@/components/shared/charts/GatewayMap'
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
export default {
  components: {
    GatewayMap
  },
  name: "Home",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }, 
    currentCommUsage() {
      return this.$store.state.apgList.List.gateway_comm_usage;
    },
    lastReadings() {
      return this.$store.state.apgList.List.home_last_readings;
    }
  },
  setup() {
    const store = useStore()

    onMounted(()=>{
      store.dispatch('gateway/findAllForMe')
      store.dispatch('gateway/getCommUsage')
      store.dispatch('user/getLastReadings', {listType: 'home_last_readings'})
    })
    return {
      content: ""
    }
  },
  methods: {
    moment: function () {
      return moment();
    },
    datetimeConversion: function (timestamp) {
      return moment.utc(timestamp).local().format('MMM Do YY, h:mm:ss a')
    }
},
}
</script>
