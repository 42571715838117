<template>
  
  <tr :class="{ 'table-danger': origin=='Not saved'}">
    <td> {{ address }}<span v-if="address2">& {{ address2 }}</span></td>

    <td>{{ description }}</td>
    <td><input class="form-control" type="number" @input="change(array_idx, $event.target)" :min="min" :max="max" :value="value"/></td>
    <td v-if="LUTS!=null"> {{ moment(LUTS) }} </td>
    <td v-if="LUTS==null">N/A </td>
    <td v-if="LUU == 0"> Sensor </td>
    <td v-if="LUU && LUU != 0"> User </td>
    <td v-if="!LUU && LUU != 0"> N/A </td>
  </tr>
</template>

<script>
  import { useStore } from "vuex";
  import moment from 'moment'
  export default {
    props: {
      idx: Number,
      array_idx: Number,
      address: Number,
      description : String,
      register_type: String,
      holding_registers_id: Number, 
      min :Number, 
      max :Number, 
      sensor_model: String,
      value: Number,
      LUTS: Date,
      origin: String,
      LUU: Number
    },
    data() {
      return {
        address2: null
      }
    },
    mounted() {
      this.checkAndEmitAddress2();
    },
    setup(props) {
      const store = useStore();
      let parametersChanged =false;

      const showModal = () => {
        store.commit("apgList/showModal", {});
      };

      const clamp = (target) => {
        let min = target.getAttribute('min');
        let max = target.getAttribute('max');
        return Math.min(Math.max(min, target.value), max);
      };

      const change = function (idx, target) {
        let value = clamp(target);
        parametersChanged = true;
        store.commit('apgList/setSingle', {
          listType: 'sensor_holdingRegisters',
          index:idx,
          name: 'sensor_data_value',
          data : value
        },{root: true});

        store.commit('apgList/setSingle', {
          listType: 'sensor_holdingRegisters',
          index:idx, name: 'origin',
          data : 'Not saved'
        },{root: true});
      };

      const updateStore = function (item, id, value) {
        store.dispatch("gateway/updateStoreGatewayReadingControl", {
          item:item,
          array_idx: props.array_idx,
          value:value,
          id:id
        });
      };

      return {
        showModal,
        change,
        updateStore,
        parametersChanged
      };
    },
    methods: {
      moment: function (date) {
        return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      },
      checkAndEmitAddress2: function () {
        if (['U32', 'I32', 'FLOAT', 'Reverse FLOAT'].includes(this.register_type)) {
          this.address2 = this.address + 1;
        }
      }
    }
  };
</script>

<style scoped>
  li {
    cursor: pointer;
  }
  li:hover {
    background-color: antiquewhite;
  }
</style>