<template>
  <div v-if="showModal" class="apg-modal-backdrop apg-modal-full">
    <div class="apg-modal">
      <header class="apg-modal-header">
         Unresponsive sensor data found
         <button class='btn btn-outline-primary' style='float:right;' height="20" width="20">
          <font-awesome-icon icon="window-close" @click="closeModal" style='font-size:25px'/>
         </button>
      </header>

      <section class="apg-modal-body table-vertical-scroll">
            <table class='table table-striped table-bordered'>
              <tr v-for="(myVal,myKey) in unresponsiveData" :key="myKey">
                <td>{{ myKey }}</td>
                <td class="text-center">{{ moment(myVal.receive_datetime).format('MMM Do YYYY') }}</td>
                <td class="text-center">{{ moment(myVal.receive_datetime).format('h:mm a') }}</td>
              </tr>
            </table>
       </section>

      <footer class="apg-modal-footer">
      </footer>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from 'vue';
import moment from 'moment';
export default {
	name: 'UnresponsiveDataModal',
  methods: {
    moment: function (date) {
      if (date != null) {
        return moment(date);
      } else {
        return '';
      }
    }
  },
	setup()
	{
		const store = useStore();
    const closeModal = () => {
      store.commit('chart/showChartModal',{show_modal:false})
    }
		return {
			showModal: computed(()=>store.state.chart.chart_modals.unresponsive_data_modal),
      closeModal,
      unresponsiveData: computed(()=>store.state.chart.unresponsive_data['group_gateway_sensor']),
		}
	}
}
</script>

<style>

  .apg-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100%;
  }

  .apg-modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .apg-modal-header,
  .apg-modal-footer {
    display: flex;
    padding:10px;
  }

  .apg-modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .apg-modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .apg-modal-body {
    position: relative;
    padding: 20px 10px;
    width:400px;
  }

.apg-modal-full {
    width: 100%;
    margin: 5;
}

.apg-modal-full .modal-content {
    min-height: 100%;
}
</style>
