import axios from 'axios'

export const audit = {
    namespaced: true,
    state:
    {
        counts: {},
        list: {},
        filters: {'limitNumber':10,'daysBack':5},
    }
    ,mutations:
    {
        setSimpleCount(state, payload) {
            state.counts[payload.tag] = payload.data.rows
        },
        setList(state, payload) {
            state.list[payload.tag] = payload.data.rows
        },
        setDaysBack(state,payload) {
            state.filters['daysBack'] = payload.data
        },
        setLimitNumber(state,payload) {
            state.filters['limitNumber'] = payload.data
        }
    }
    ,actions: {
        async getSimpleCount({ rootState, commit, state }, payload) {
            /* EXPECTS
                groupBy = what field in DB will be used to group the data
                tag = where to store the data in the store
            */
            try {
                let base_url = rootState.settings.api_base
                let url = base_url + 'audit/counts/'
                    + payload.groupBy + '/'
                    + state.filters['daysBack'] + '/'
                    + state.filters['limitNumber'] + '/'
                    + rootState.apgList.dropdown_values['audit_filter_user'][0] + '/'
                    + rootState.apgList.dropdown_values['audit_filter_action_type'][0]
                let rs = await axios.get(url)
                payload['data'] = rs.data
                console.log('Called URL ' + url + ' and result was ',rs)
                commit('setSimpleCount', payload)
            } catch (err) {
                console.log('Failed to get simple audit count, payload was', payload)
                console.log('Failed to get simple audit count, error was', err)
            }
        },
        async getSimpleList({ rootState, commit }, payload) {
            /* EXPECTS
                countType = what field in DB will be used to group the data
                daysBack = how many days back to run the counts for
                limitNumber = how many rows from result set to return ( usually used as TOP 10 ranking for example )
            */
            try {
                let base_url = rootState.settings.api_base
                let url = base_url + 'audit/list/'
                    + payload.actionType + '/'
                    + payload.limitNumber
                let rs = await axios.get(url)
                payload['data'] = rs.data
                commit('setList', payload)
            } catch (err) {
                console.log('Failed to get simple audit list, payload was', payload)
                console.log('Failed to get simple audit list, error was', err)
            }
        },
    }
}