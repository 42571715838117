<template>
  <div class="col-md-12">
    <div class="card card-container">
      <img
        class="profile-img-card"
      />
      <Form @submit="handleLogin" :validation-schema="schema" v-show="isLogin && !isVerification">
        <div class="form-group">
          <label for="username">Username or Email</label>
          <Field name="username" type="text" class="form-control" />
          <ErrorMessage name="username" class="error-feedback" />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <Field name="password" type="password" class="form-control" />
          <ErrorMessage name="password" class="error-feedback" />
        </div>

        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Login</span>
          </button>
        </div>

        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      </Form>

      <Form @submit="handleForgotPassword" v-show="!isLogin">
        <div class="form-group">
          <label for="email">Email</label>
          <Field name="email" type="email" class="form-control" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            />
            <span>Reset Password</span>
          </button>
        </div>
      </Form>

      <Form @submit="handleResendVerification" v-show="isVerification">
        <div class="form-group">
          <label for="email">Email</label>
          <Field name="email" type="email" class="form-control" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
            ></span>
            <span>Resend Email Verification</span>
          </button>
          <div class="form-group">
            <div v-if="verifMsg" class="alert alert-danger" role="alert">
              {{ verifMsg }}
            </div>
          </div>
        </div>
      </Form>

      <div class="form-group" v-if="!isVerification">
          <button class="btn btn-primary btn-block" @click="isLogin = !isLogin">
            <span v-if="isLogin">Forgot Password</span>
            <span v-if="!isLogin">Cancel</span>
          </button>
        </div>

      <div class="form-group" v-if="isLogin">
        <button class="btn btn-primary btn-block" @click="isVerification = !isVerification">
          <span v-if="!isVerification">Resend Email Verification</span>
          <span v-if="isVerification">Cancel</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import socketInstance from "@/services/Socket";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username is required!"),
      password: yup.string().required("Password is required!"),
    });

    return {
      loading: false,
      message: "",
      schema,
      isLogin: true,
      isVerification: false,
      verifMsg: '',
    };
  },
  computed: {
    afterLoginRoute() {
      let user_type_id = this.$store?.state?.auth?.user?.user?.Info?.user_type;
      if (user_type_id == 5) {
        return '/admin';
      }
      return '/home';
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push(this.afterLoginRoute);
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          socketInstance.loadUser();
          this.$router.push(this.afterLoginRoute);
        },
        (error) => {
          this.loading = false;
          this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        }
      );
    },
    handleForgotPassword(user){
      console.log(user)
      this.$store.dispatch("auth/forgotPassword", user).then(
        () => {
          this.$router.push(this.afterLoginRoute);
          alert("Password reset email sent. Please check your email.");
          console.log('yyeerr');
        },
        (error) => {
          console.log('nnooppee' , error)
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    handleResendVerification(email) {
      console.log('handleResendConfirmation for email: ', email);
      this.$store.dispatch('auth/resendVerificationEmail', email)
          .then(res => {
            console.log('resendVerificationEmail response: ', res);
            this.verifMsg = res.data.status.message;
            this.$router.push(this.afterLoginRoute);
          })
          .catch(err => {
            console.log('resendVerificationEmail error: ', err);
            this.loading = false;
            this.verifMsg = err.verifMsg || err.toString();
          });
    }
  },


  //.profile-img-card {
//  width: 96px;
//  height: 96px;
//  margin: 0 auto 10px;
//  display: block;
//  -moz-border-radius: 50%;
//  -webkit-border-radius: 50%;
//  border-radius: 50%;
//  background-image: var(--profileImage);
//  background-size: cover;
//  background-position: center;
//  background-repeat: no-repeat;
//}
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  content: var(--profileImage);
}

.error-feedback {
  color: red;
}
</style>
