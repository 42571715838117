<template>
    <div class="card-block table-responsive">
<!-- Timing table     -->
      <table class="table table-bordered text-center">
        <tr>
          <th v-for="attr in timingAttrs" :key="attr.attribute_info_id"> {{attr.attribute_pretty_name}} </th>
        </tr>
        <tr>
          <td v-for="(attr, idx) in timingAttrs" :key="attr.attribute_info_id">
            <input class="form-control text-center"
                   type="number"
                   :min="1"
                   :value="attr.attribute_value"
                   @input="updateTiming(idx, $event.target.value)"
                   v-bind:class="attr.status"
            />
          </td>
        </tr>
      </table>
    </div>
</template>

<script>
import { useStore } from "vuex";
import {computed} from "vue";

export default {
  name: "GatewayTimingTable",
  setup() {
    const store = useStore();
    const listType = 'gateway_timings';

    const updateTiming = (idx, value) => {
      // update the value
      store.commit('apgList/setListIndexProp', {
        listType: listType,
        idx: idx,
        prop: 'attribute_value',
        data: value
      });

      // update the status
      store.commit('apgList/setListIndexProp', {
        listType: listType,
        idx: idx,
        prop: 'status',
        data: 'changed'
      });
    };

    return {
      store,
      listType,
      gatewayId: computed(() => store.state.apgList.ids.gateway.gateway),
      timingAttrs: computed(() => store.state.apgList.List[listType]),
      updateTiming,
    }
  },
}
</script>

<style scoped>
input.changed {
  border: 3px solid orange;
}
</style>