<template>
  <div id="app">
    <Menu />
  </div>
</template>

<script>
import Menu from "./components/shared/Menu.vue";

export default {
  components: {Menu},
  
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {},
};
</script>