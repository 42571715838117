import axios from 'axios'
import { useToast } from 'vue-toastification';

const toast = useToast();

export const group = {
  namespaced: true,

  state: {
    url_part: 'groups',
    url_my_group: 'myGroups',
    url_group_for_user: 'groups/user/',
    url_remove_user_from_group: 'group/remove_user/',
    url_add_user_to_groups: 'user/add_groups/',
    url_add_groups_to_gateway: 'gateway/add_groups/',
    current_group_id: null,
    current_group_name: '',
    checkedGroups: [],
    edit_group_modal_shown: false,
    add_group_modal_shown: false,
  },

  mutations: {
    hideEditModal(state) {
      state.edit_group_modal_shown = false
    },
    showEditModal(state, payload) {
      state.edit_group_modal_shown = true
      state.current_group_id = payload.idx
      state.current_group_name = payload.nm
    },
    toggleAddGroupModal(state,payload) {
      state.current_group_name = ''
      state.add_group_modal_shown = payload
    },
    setCurrentGroupName(state, payload) {
      state.current_group_name = payload
    },
  },
  actions: {
    async findAll({ rootState, state, commit }, payload) {
      let url = rootState.settings.api_base + state.url_part
      try {
        let rs = await axios.get(url)
        console.log('Group response was ', rs)
        commit(
          'apgList/setLists',
          { listType: payload.listType, data: rs.data },
          { root: true }
        )
      } catch (err) {
        console.error('Failed to load group list.  Error was ', err)
      }
    },
    async findAllForUser({ rootState, state, commit }, payload) {
      let url =
        rootState.settings.api_base +
        state.url_group_for_user +
        rootState.apgList.ids['user'].user_id;

      try {
        let rs = await axios.get(url);
        console.log('found group list for user', rs.data)
        commit('apgList/setLists', { listType: payload.listType, data: rs.data }, { root: true });
      } catch (err) {
        console.error('Failed to load group info for user.  Error was ')
        console.error({ err })
      }
    },
    async findAllForMe({ rootState, state, commit }, payload) {
      let url = rootState.settings.api_base + state.url_my_group
      console.log('url is', url)
      try {
        let rs = await axios.get(url)
        console.log('Group response was ', rs)
        commit(
          'MyView/addParents',
          { parent: payload.listType, data: rs.data },
          { root: true }
        )
      } catch (err) {
        console.error('Failed to load group list.  Error was ', err)
      }
    },
    async removeUserFromGroup({ commit, rootState, state }, payload) {
      let url =
        rootState.settings.api_base +
        state.url_remove_user_from_group +
        rootState.apgList.ids[payload.listType].user_id +
        '/' +
        rootState.apgList.ids['group'].group_id
      console.log('removing user url from group list is ', url)
      try {
        let rs = await axios.delete(url);
        commit('apgList/setLists', { listType: payload.listType, data: rs.data }, { root: true });
        commit('apgList/setID', { listType: 'group_user', idType: 'user_id', _id: null }, { root: true });
        toast.success('Removed user from group!');
      } catch (err) {
        console.error('Failed to remove user from group.  Error was ');
        console.error({ err });
        toast.error('Unable to remove user from group. Please try again later.');
      }
    },
    async removeGatewayFromGroup({ commit, rootState, state }, payload) {
      let url =
        rootState.settings.api_base +
        state.url_remove_gateway_from_group +
        rootState.apgList.ids[payload.listType].gateway_id +
        '/' +
        rootState.apgList.ids[payload.listType].group_id
      try {
        let rs = await axios.delete(url)
        commit(
          'apgList/setLists',
          { listType: payload.listType, data: rs.data },
          { root: true }
        )
      } catch (err) {
        console.error('Failed to remove gateway from group.  Error was ')
        console.error({ err })
      }
    },
    async addGroupsToUser({ state, commit, dispatch, rootState }, payload) {
      let checked = rootState.apgList?.checked?.[payload.listType];
      if (!checked || checked.length === 0) {
        return;
      }

      let group_list = checked.join('-')
      let group_str = checked.length > 1? 'groups' : 'group';
      console.log('group list is ', group_list)
      let url =
        rootState.settings.api_base +
        state.url_add_user_to_groups +
        rootState.apgList.ids['user'].user_id +
        '/' +
        group_list
      console.log('url ', url);
      try {
        let rs = await axios.put(url);
        console.log('addGroupsTouser', rs);
        commit('apgList/clearChecked', payload, { root: true });
        dispatch('findAllForUser', payload);
        toast.success(`Added ${group_str} to user!`);
      } catch (err) {
        console.log('addGroupsTouser: error was: ', err);
        toast.error(`Unable to add ${group_str} to user. Please try again later.`);
      }
    },
    async addGroupsToGateway({ state, commit, dispatch, rootState }, payload) {
      let checked = rootState.apgList?.checked?.[payload.listType];
      if (!checked || checked.length === 0) {
        return;
      }

      let group_list = checked.join('-');
      let group_str = checked.length > 1? 'groups' : 'group';
      console.log('group list is ', group_list)
      let url =
        rootState.settings.api_base +
        state.url_add_groups_to_gateway +
        rootState.apgList.ids['gateway'].gateway +
        '/' +
        group_list
      console.log('url ', url)
      try {
        let rs = await axios.put(url, {});
        console.log('addGroupsToGateway', rs);
        commit('apgList/clearChecked', payload, { root: true });
        dispatch('gateway/findAllForGateway', payload, {root:true});
        toast.success(`Added ${group_str} to gateway!`);
      } catch (err) {
        console.log('addGroupsToGateway: error was: ', err);
        toast.error(`Unable to add ${group_str} to user. Please try again later.`);
      }
    },    
    async updateGroupName({state, rootState,commit,dispatch}) {
      let curName = state.current_group_name;
      let curID = state.current_group_id;
      let url = rootState.settings.api_base + 'group/updateName/' + curID + '/' + curName;
      try {
        await axios.patch(url);
        commit('hideEditModal');
        dispatch('findAll',{listType:'group'});
        toast.success('Changed group name!');
      } catch(err) {
        console.error('Failed to update group name',err);
        toast.error('Failed to change group name. Please try again later.');
      }
    },
    async addNewGroup({state, rootState,commit,dispatch}) {
      let curName = state.current_group_name
      let url = rootState.settings.api_base + 'group/add/' + curName
      try {
        await axios.put(url, {});
        commit('toggleAddGroupModal',false);
        dispatch('findAll',{listType:'group'});
        toast.success('Added new group!');
      } catch(err) {
        console.error('Failed to update group name',err);
        toast.error('Unable to add new group. Please try again later.');
      }
    }    
  },
  getters: {},
}
