<template>
  <li class="list-group-item">
    <div class="row">
      <attributeBoolean :name="keyName" :value="value" :id="idx" listType='user_attribute' :attInfoId="attInfoId" :ownerId="ownerId" :attId="attId" :disabled="disabled" v-if="type=='boolean'" />
      <attributeEmail :name="keyName" :value="value" :id="parseInt(idx)" listType='gateway_attribute' :attInfoId="attInfoId" :ownerId="parseInt(ownerId)" :attId="attId" :disabled="disabled" v-if="type=='email'" />
      <attributeText :name="keyName" :value="value.toString()" :id="parseInt(idx)" listType='gateway_attribute' :attInfoId="attInfoId" :ownerId="parseInt(ownerId)" :attId="attId" :disabled="disabled" v-if="type=='text'" />
      <attributeDate :name="keyName" :value="value" :id="parseInt(idx)" listType='gateway_attribute' :attInfoId="attInfoId" :ownerId="parseInt(ownerId)" :attId="attId" :disabled="disabled" v-if="type=='date'" />
      <attributeNumber :name="keyName" :value="value" :id="parseInt(idx)" listType='gateway_attribute' :attInfoId="attInfoId" :ownerId="parseInt(ownerId)" :attId="attId" :disabled="disabled" v-if="type=='number'" />
      <attributeDateTime :name="keyName" :value="value" :id="parseInt(idx)" listType='gateway_attribute' :attInfoId="attInfoId" :ownerId="parseInt(ownerId)" :attId="attId" :disabled="disabled" v-if="type=='dateTime'" />
    </div>
  </li>
</template>

<script>
  import AttributeBoolean from "../attribute_render/AttributeBoolean.vue";
  import AttributeEmail from "../attribute_render/AttributeEmail.vue";
  import AttributeText from "../attribute_render/AttributeText.vue";
  import AttributeDate from "../attribute_render/AttributeDate.vue";
  import AttributeDateTime from "../attribute_render/AttributeDateTime.vue";
  import AttributeNumber from "../attribute_render/AttributeNumber.vue";
  
  export default {
    props: {
      value: [String, Number, Date],
      keyName: String,
      idx: Number,
      type: String,
      attId: Number,
      disabled: Boolean,
      attInfoId: Number,
      ownerId: Number
    },
    components: {
      AttributeBoolean,
      AttributeEmail,
      AttributeText,
      AttributeDate,
      AttributeNumber,
      AttributeDateTime,
    },
    methods:{
      change(index, value) {
        console.log('index is ', index)
        console.log('value is ', value)
      }
    },
    setup() {
    },
  };
</script>