<template>
    <div>
        <div class="d-flex" style="padding:15px;border:1px solid blanchedalmond">
            <div class="p2 m2">
                <h2>FILTER BAR</h2>
            </div>

            <div class="p2">
                <div class="input-group">
                    <label class="list-group-item" style="margin-left:20px">Days back</label>
                    <select class="form-control" @input="setDaysBack" v-model="daysBack" style="width:150px;">
                        <option value="1">1 day</option>
                        <option value="5">5 days</option>
                        <option value="10">10 days</option>
                        <option value="15">15 days</option>
                        <option value="20">20 days</option>
                        <option value="30">30 days</option>
                    </select>
                </div>
            </div>

            <div class="p2">
                <div class="input-group">
                    <label class="list-group-item" style="margin-left:20px">User</label>
                    <ApgDropdown valueField="system_user_id"
                        descriptionField="username"
                        table="system_user"
                        :showDefaultValue="true" defaultEmptyValue="0" defaultEmptyValueMessage="*ALL*"
                        :idx="0" listType="audit_filter_user" type="Regular" />
                </div>
            </div>

            <div class="p2">
                <div class="input-group">
                    <label class="list-group-item" style="margin-left:20px">Action type</label>
                    <ApgDropdown valueField="audit_action_id"
                        descriptionField="action_tag"
                        table="audit_actions"
                        :showDefaultValue="true" defaultEmptyValue="0" defaultEmptyValueMessage="*ALL*"
                        :idx="0" listType="audit_filter_action_type" type="Regular" />
                </div>
            </div>

            <div class="p2">
                <div class="input-group">
                    <label class="list-group-item" style="margin-left:20px">TOP X</label>
                    <select class="form-control" @input="setLimitNumber" v-model="limitNumber" style="width:150px;">
                        <option value="10">Top 10</option>
                        <option value="20">Top 20</option>
                        <option value="30">Top 30</option>
                        <option value="50">Top 50</option>
                        <option value="100">Top 100</option>
                        <option value="5">Top 5</option>
                    </select>
                </div>
            </div>

            <div class="p2">
                <button class="btn btn-secondary btn-md" style="margin-left:5px"
                @click="runDB"
                >Apply</button>
            </div>

        </div>

        <div class="row" style="margin:10px">
            <div class="col-md-4">
                <AuditCountTile groupBy="audit_event_desc" tag="Events" :width="300" :height="305" />
            </div>
            <div class="col-md-4">
                <AuditCountTile groupBy="action_tag" tag="Action tags" :width="300" :height="305" />
            </div>
            <div class="col-md-4">
                <AuditCountTile groupBy="username" tag="user" :width="300" :height="305" />
            </div>
        </div>
    </div>
</template>

<script>
import AuditCountTile from '@/components/audit/auditCountTile'
import ApgDropdown from '@/components/shared/lists/apg-dropdown'
import {computed} from 'vue'
import {useStore} from 'vuex'

export default {
    components: {
        AuditCountTile,
        ApgDropdown
    },
    setup() {
        let store = useStore()
        let daysBack = store.state.audit.filters['daysBack']
        let limitNumber = store.state.audit.filters['limitNumber']

        function setDaysBack(e) {
            store.commit('audit/setDaysBack',{data:parseInt(e.target.value)})
        }
        function setLimitNumber(e) {
            store.commit('audit/setLimitNumber',{data:parseInt(e.target.value)})
        }

        function runDB() {
            store.dispatch('audit/getSimpleCount',{groupBy:'audit_event_desc',tag:'Events'})
            store.dispatch('audit/getSimpleCount',{groupBy:'action_tag',tag:'Action tags'})
            store.dispatch('audit/getSimpleCount',{groupBy:'username',tag:'user'})
        }

        let userID = computed(() => {return store.state.apgList.dropdown_values['audit_filter_user'][0]})
        let actionID = computed(() => {return store.state.apgList.dropdown_values['audit_filter_action_type'][0]})

        return {
            daysBack,
            limitNumber,
            userID,
            actionID,
            setDaysBack,
            setLimitNumber,
            runDB,
        }
    }
}

</script>

<style scoped>
h2 {
    font-size: 16pt;
    color: blanchedalmond;
    vertical-align: middle;
    padding: 7px;
}
</style>