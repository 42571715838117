<template>
    <div
      v-if="lockSuccessful"
      class="mt-4 p-5 bg-success text-white rounded text-center"
    >
      <div>
        <div>Your email has been locked from further invitations</div>
        <div class="p-5">
          If this was done in error and you'd like to register. Please click
          <router-link to="/register"
            style='color:white;font-weight:bold;text-decoration:underline;margin-left:5px;margin-right:5px;font-size:14pt'>here</router-link>
        </div>
      </div>
      </div>

    <div v-if="!lockSuccessful" class="mt-4 p-5 bg-danger text-white rounded text-center" >
      There was an error locking your email from receiving further invitations. Please click the link in
      your email again. If you still receive this error please contact the Nomad
      Explorer team.
    </div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  name: 'LockEmail',
  setup() {
    const store = useStore();
    const route = useRoute();

    onMounted(() => {
      store.dispatch('user/lockEmail', route.params.email);
    })

    return {
      lockSuccessful: computed(() => store.state.user.invite_lock_status),
    }
  },
}
</script>
