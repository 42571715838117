import axios from 'axios';
import process from 'process';
const uninterceptedAxiosInstance = axios.create();

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
  login(user) {
    return uninterceptedAxiosInstance
      .post(API_URL + 'user/login', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        //if (response.data.accessToken) {
        if (response.data.token) {
          console.log('setting local storage')
          
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  changePassword(pass) {
    /*return uninterceptedAxiosInstance
      .post(API_URL + 'user/login', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        //if (response.data.accessToken) {
        if (response.data.token) {
          console.log('setting local storage')
          
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });*/
      console.log(pass)
  }

  logout() {
    console.log('emptying local storage')
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }

  register(user) {
    return uninterceptedAxiosInstance.post(API_URL + 'user/register', {
      username: user.username,
      contact_email: user.contact_email,
      password: user.password,
      first_name:user.first_name,
      last_name:user.last_name
    });
  }

  getAPIUrl() {
    return API_URL;
  }

  getAIUrl() {
    return process.env.VUE_APP_AI_URL;
  }
}

export default new AuthService();
