import AuthService from '../services/auth.service';
const API_URL = AuthService.getAPIUrl()
const AI_API_URL = AuthService.getAIUrl();

export const settings = {
	namespaced: true,
	state: {
		api_base: API_URL,
		ai_api_base: AI_API_URL,
		is_loading: false,
		loading_message: "Please wait...Your data is loading",
		show_basic_modal: false,
	},
	mutations: {
		setIsLoading(state,	payload)
		{
			state.is_loading = payload;
		},
		setLoadingMessage(state,payload)
		{
			state.loading_message = payload;
		},
		setShowBasicModal(state, payload)
		{
			state.show_basic_modal = payload;
		}
	},
	getters: {
	},
};
