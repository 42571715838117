<template>
  <mapbox-map
      :accessToken="accessToken"
      mapStyle="mapbox://styles/mapbox/navigation-day-v1"
      :center="coordinates"
      :zoom="3"
      :auto-resize="true"
      style="height: 500px"
  >
    <mapbox-marker v-for="d in gatewayList" :key="d.gatewayid" :lngLat="[d.longitude,d.latitude]">
      <mapbox-popup>
        <div>{{moment(d.lastupdate)}}</div>
      </mapbox-popup>    
    </mapbox-marker>
  </mapbox-map>
</template>

<script>
import { MapboxMap, MapboxMarker, MapboxPopup } from 'vue-mapbox-ts'
import {computed} from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'

export default {
  components: {
    MapboxMap,
    MapboxMarker,
    MapboxPopup,
  },
  setup() {
    let store = useStore();

    return {
      accessToken:
        'pk.eyJ1IjoiYmVub2l0ZHVyZXQiLCJhIjoiY2w2OXhjeHMyMTZ0ajNpbzVhM2MwaHlteiJ9.4R_hyzrZFN7bZrix5X_Nnw',
      coordinates: [-96.22526430339026, 38.80948251839888],
      gatewayList: computed(() => store.state.apgList.List['gateway_GPS_Location']),
    }
  }, methods: {
      moment: function (date) {
        if (date != null) {
          return moment(date).format('MMM Do YY, h:mm:ss a');
        } else  {
          return '';
        }
      },
    }
}
</script>
