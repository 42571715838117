import AuthService from '../services/auth.service';
import axios from 'axios';
const uninterceptedAxiosInstance = axios.create();

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state:{...initialState,verifyRegister: false,verifyRegisterError:false},
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },

    async changePassword({rootState, commit },pass) {
      try {
        let rs = await uninterceptedAxiosInstance.post(rootState.settings.api_base + 'user/changePassword/', {
          password: pass.password,
          passwordConfirm: pass.passwordConfirm,
          uuid: pass.uuid,
        })
        console.log('server response was ', rs)
        commit('changePasswordSuccess');
        return Promise.resolve(rs.data);
      } catch (err) {
        console.log('Failed to load gateway list.  Error was ', err)
        commit('changePasswordFailure');
          return Promise.reject(err.response.data);
      }
    },

    async register ({ rootState, commit}, user) {
      let rs = await uninterceptedAxiosInstance.post(rootState.settings.api_base + 'user/register/', {
        username: user.username,
        contact_email: user.contact_email,
        password: user.password,
        first_name:user.first_name,
        last_name:user.last_name
      })

      console.log('Gateway response was ', rs);

      if (rs.data.status.code === 200) {
        commit('registerSuccess');
        return Promise.resolve(rs.data);
      } else if (rs.data.status.code === 400) {
        commit('registerFailure');
        return Promise.reject(rs.data.status.message);
      }
    },
    async verifyRegister({rootState, state},uuid) {
      try {
        let rs = await uninterceptedAxiosInstance.get(rootState.settings.api_base + 'user/verify/' + uuid)
        console.log(rs)
        if(rs.data.status.id === 200)
        {
          state.verifyRegister = true
          state.verifyRegisterError = false
        }
        else 
        {
          state.verifyRegister = false
          state.verifyRegisterError = true
        }
      }
      catch(error) {
        console.log('failed to verify user registration',uuid)
      }
    }, 
    async forgotPassword({ rootState , commit}, user) {
      try {
        let rs = await uninterceptedAxiosInstance.post(rootState.settings.api_base + 'user/forgotPassword/', {
          email: user.email,
        })
        console.log('Response was ', rs)
        return Promise.resolve(rs.data);
        
      } catch (err) {
        console.log('Failed to find email.  Error was ', err)
        commit('recoveryFailure');
        return Promise.reject(err);
      }
    },
    async refreshCurrentUserAttributes({rootState, commit}, path) {
      if (path != '/login') {
        let url = rootState.settings.api_base + 'users/refresh';
        console.log('user auth info refresh ', url);
        let rs = await axios.get(url);
        commit('updateUserAuthInfo', rs.data.Info);
        return Promise.resolve(rs);
      }
      return Promise.resolve(true);
    },

    async resendVerificationEmail({rootState, commit}, email) {
      try {
        let rs = await uninterceptedAxiosInstance.post(
          rootState.settings.api_base + 'user/resendVerification', email)
        console.log('Response was ', rs)
        return Promise.resolve(rs);
      } catch (err) {
        console.log('Failed to resend verification email.  Error was ', err)
        commit('recoveryFailure');
        return Promise.reject(err);
      }
    },

    async decodeString({rootState}, string) {
      try {
        let url = rootState.settings.api_base + 'general/decode';
        let res = await axios.post(url, {msg: string});
        return Promise.resolve(res.data.decoded);
      } catch (e) {
        console.log('Failed to decode string.  Error was ', e)
        return Promise.reject(e);
      }
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    recoveryFailure(state) {
      state.status.recovery = false;
    }, 
    changePasswordSuccess(state) {
      state.status.changePassword = true;
    }, 
    changePasswordFailure(state) {
      state.status.changePassword = false;
    },
    updateUserAuthInfo(state, info) {
      let local_user = JSON.parse(localStorage.getItem('user'));
      local_user.user.Info = info;
      localStorage.setItem('user', JSON.stringify(local_user));
      state.user = local_user;
    }
  }
};
