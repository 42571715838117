<template>
  <div class="card" style="margin-top: 20px">
    <div class="card-header align-content-between">
      <h5 class="card-title">Gateway Reading Control</h5>
      <button @click="ConfirmUpdateGatewayReadingControl" class="btn btn-outline-primary" style="float: right" id="updateQueueBtn">
        <font-awesome-icon icon="satellite" style="font-size: 11pt" />
        Save and Queue Message
      </button>
      <button @click="addNewGCR" class="btn btn-outline-primary" style="float: right">
        <font-awesome-icon icon="plus" style="font-size: 11pt" />
        Add
      </button>
    </div>
    <div class="card-block table-responsive">
      <table class="table table-bordered text-center">
        <tr>
          <th style="width: 10%">Sensor</th>
          <th style="width: 10%">Address To Read</th>
          <th style="width: 10%">Length</th>
          <th style="width: 10%">Power Line</th>
          <th style="width: 10%">Function</th>
          <th>Function Description</th>
          <th></th>
        </tr>
        <GatewayReadingControlItem
          :idx="parseInt(gc.gateway_reading_control_id)"
          :array_idx="gc_index"
          :sensor_address="parseInt(gc.sensor_address)"
          :sensor_register ="gc.sensor_register"
          :length ="parseInt(gc.length)"
          :sensor_type_name ="gc.sensor_type_name"
          :power_line ="gc.power_line"
          :status ="gc.status"
          :application_type ="gc.application_type"
          :application_type_description ="gc.application_type_description"
          v-bind:key="gc.gateway_reading_control_id"
          v-for="(gc,gc_index) in gatewayControlList"
        />
      </table>
    </div>
    <gateway-timing-table/>
  </div>
</template>

<script>
  import { useStore } from "vuex";
  import {computed} from "vue";
  import GatewayReadingControlItem from "./gatewayControlReading-item";
  import GatewayTimingTable from "@/components/shared/lists/gatewayTimingTable.vue";
  import {onBeforeRouteLeave} from "vue-router";
  export default {
    name: "gatewaycontrolreading",
    components: {
      GatewayReadingControlItem,
      GatewayTimingTable,
    },
    props: {
      source: String,
      usage: String,
      showPlus: Boolean,
      showEraser: Boolean,
      canClick: Boolean,
      showRemoveFromUser: Boolean,
      showRemoveFromGateway: Boolean,
      status : String,
    },
    setup() {
      const store = useStore();
      const changeFunction = function (value) {
        store.dispatch("gateway/changeFunctionGatewayReadingControl", {array_value:value});
      };

      const ConfirmUpdateGatewayReadingControl = () => {
        store.dispatch("gateway/SendGatewayReadingControl", ).then(function () {
          console.log('click update To swarm')
        });
      };
      const addNewGCR = () => {
        store.dispatch("gateway/AddNewGCR", )        
      };

      onBeforeRouteLeave(() => {
        let gcr = store.state.apgList.List.gateway_control_reading;
        let timings = store.state.apgList.List.gateway_timings;

        // if any unsaved changes in the gateway controls or gateway timings
        if ((gcr && gcr?.find((item) => item.status === "new" || item.status === "updated")) ||
            (timings && timings?.find((item) => item.status === "changed"))) {
          const answer = window.confirm('Do you really want to leave? You have unsaved changes in the Gateway Control tab.');
          if (!answer) return false;
        }
      });

      return {
        gatewayControlList: computed(() => store.state.apgList.List['gateway_control_reading']),
        changeFunction,
        addNewGCR,
        ConfirmUpdateGatewayReadingControl,
      };
    },
  };
</script>
<style scoped>
  .noItemsSpan {
    height: 200px;
    width: 100%;
    background-color: aliceblue;
  }
</style>