const initialState = {
	last_update_dt:"",
	last_update_user:"",
	is_error: false,
	current_status_text:"No activity performed",
};

export const status_bar = {
  namespaced: true,
  state: initialState,
  actions: {
  },
  mutations: {
    setStatus (state, payload) {
      state.current_status_text = payload.msg
      state.is_error = payload.is_error
    },
  },
  getters: {
  }
};
