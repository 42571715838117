<template>
  <div v-if="showModal" class="apg-modal-backdrop apg-modal-full">
    <div class="apg-modal">
      <header class="apg-modal-header">
         {{modalTitle}}
         <button class='btn btn-outline-primary' style='float:right;' height="20" width="20">
          <font-awesome-icon icon="window-close" @click="closeModal" style='font-size:25px'/>
         </button>
      </header>

      <section class="apg-modal-body">
          <slot></slot>
       </section>

      <footer class="apg-modal-footer">
      </footer>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from 'vue';
export default {
	name: 'BasicModal',
  props: {
    modalTitle: String,
    source: String,
  },
	setup(props)
	{
		const store = useStore();
    const closeModal = () => {
      store.commit('apgList/hideModal',{listType:props.source})
    }
		return {
			showModal: computed(()=>store.state.apgList.modals[props.source]),
      closeModal
		}
	}
}
</script>

<style>

  .apg-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100%;
  }

  .apg-modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .apg-modal-header,
  .apg-modal-footer {
    display: flex;
    padding:10px;
  }

  .apg-modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .apg-modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .apg-modal-body {
    position: relative;
    padding: 20px 10px;
    width:400px;
  }

.apg-modal-full {
    width: 100%;
    margin: 5;
}

.apg-modal-full .modal-content {
    min-height: 100%;
}
</style>
