<template>
  <div class="col-md-12">
    <div class="card card-container">
      <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      />
      <Form @submit="handleRegister" :validation-schema="schema">
        <div v-if="!successful">
          <div class="form-group">
            <label for="username">Username</label>
            <Field name="username" type="text" class="form-control" />
            <ErrorMessage name="username" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="first_name">First Name</label>
            <Field name="first_name" type="text" class="form-control" />
            <ErrorMessage name="first_name" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="last_name">Last Name</label>
            <Field name="last_name" type="text" class="form-control" />
            <ErrorMessage name="last_name" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="contact_email">Email</label>
            <Field name="contact_email" type="email" class="form-control" />
            <ErrorMessage name="contact_email" class="error-feedback" />
          </div>
          <!-- TODO require user to put it in twice -->
          <div class="form-group pb-3">
            <label for="password">Password</label>
            <Field name="password" type="password" class="form-control" />
            <ErrorMessage name="password" class="error-feedback" />
          </div>

          <div class="form-group pb-3">
            <div id="recaptcha" class="g-recaptcha"
                 :data-sitekey="siteKey"
                 data-size="normal"
            >
            </div>
          </div>

          <div class="form-group">
            <button class="btn btn-primary btn-block" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              Sign Up
            </button>
          </div>
        </div>
      </Form>

      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import process from 'process';

export default {
  name: "Register",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const siteKey = process.env.VUE_APP_RECAPTCHA_KEY;
    return {
      siteKey,
    }
  },
  data() {
    const schema = yup.object().shape({
      username: yup
        .string()
        .required("Username is required!")
        .min(3, "Must be at least 3 characters!")
        .max(20, "Must be maximum 20 characters!"),
      first_name: yup
        .string()
        .required("First name is required!"),
      last_name: yup
        .string()
        .required("Last name is required!"),
      contact_email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!"),
        // .max(50, "Must be maximum 50 characters!"),
      password: yup
        .string()
        .required("Password is required!"),
        //.min(8, "Must be at least 8 characters!")
        //.max(40, "Must be maximum 40 characters!")
        //.matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"),
    });

    return {
      successful: false,
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }

    // include the google recaptcha
    const recaptcha = document.createElement('script');
    recaptcha.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
    recaptcha.async = true;
    recaptcha.defer = true;
    document.head.appendChild(recaptcha);
  },
  methods: {
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;

      try {
        if (!window.grecaptcha.getResponse()) {
          this.message = 'Please complete the captcha.';
          this.successful = false;
          this.loading = false;
          return;
        }

        this.$store.dispatch("auth/register", user)
            .then(() => {
                  this.message = 'Thank you for registering, please check your email in order to complete registration.';
                  this.successful = true;
                  this.loading = false;
                }, (error) => {
                  this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                  this.successful = false;
                  this.loading = false;
                }
            );
      } catch (error) {
        console.log(error);
        this.message = 'An error occurred while registering.';
        this.loading = false;
        this.successful = false;
      }
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.error-feedback {
  color: red;
}
</style>
