
const getAllowedRoutes = (user_type) => {
  // doesn't include public pages, because we don't need to guard those
  let nonPublicPages = ['/gateways', '/profile', '/home'];
  let adminPages = ['/admin', '/auditDB'];

  switch (user_type) {
    case '1':
      // admin
      // everything is allowed
      return nonPublicPages.concat(adminPages);
    case '3':
      // phantom should only use public pages
      return [];
    case '4': {
      // sales
      // no access to audit page
      return nonPublicPages.concat('/admin');
    }
    case '5':
      // production
      // only admin board access
      return ['/admin'];
    default:
      // customer
      return nonPublicPages;
  }
}

export default getAllowedRoutes;