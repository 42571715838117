<template>
    <div class='card' :style="{ width: width + 'px', height: height + 'px' }">
        <div class='card-header'>{{ label }}</div>
        <div class='card-body'>
            <table class="table" Border="1">
                <tr v-for="(i, idx) in items" :key="idx">
                    <td class="table_header">{{ i.groupby }}</td>
                    <td class="table_num">{{ i.cnt }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
    props:
    {
        groupBy: String,
        tag: String,
        width: Number,
        height: Number,
    },
    setup(props) {
        let store = useStore()
        const textWidthMultiplier = 0.6
        let textWidth = props.width * textWidthMultiplier;
        let numWidth = props.width * (1 - textWidthMultiplier);
        return {
            items: computed(() => store.state.audit.counts[props.tag] || []),
            label: computed(() => 'Count by ' + props.tag),
            textWidth,
            numWidth,
        }
    }
}
</script>

<style scoped>
div.card {
    font-size: 10pt;
    overflow-y: auto;
}
td{
border-collapse:collapse;
border:1px solid gray;
}
div.card-header {
    padding:2px;
    text-align:center;
}
.table_num {
    text-align:right;
}
</style>
