<template>
  <div class="card" style="margin-top: 20px; height: 80vh; display: flex; flex-direction: column;">
    <div class="card-header">
      Holding Registers
      <button @click="UpdateNewHoldingParameters" class="btn btn-outline-primary" style="float: right">
        <font-awesome-icon icon="satellite" style="font-size: 11pt" />
        Save & Update Queue HR
      </button>
      <button v-if="holdingParmsExist" @click="readAll" class="btn btn-outline-primary" style="float: right">
        <font-awesome-icon icon="satellite" style="font-size: 11pt" />
        Read All
      </button>
    </div>
    <div class="card-block table-responsive" style="flex: 1; overflow-y: auto;">
      <table class="table table-bordered text-center text-nowrap w-100">
        <tr class="sticky-top-z1">
          <th style="width: 10%">Address</th>
          <th style="width: 20%">Description</th>
          <th style="width: 10%">Value</th>
          <th style="width: 25%">Last Updated</th>
          <th style="width: 10%">Source</th>
        </tr>
        
        <SensorHoldingRegistersItem
          :idx="parseInt(hr.address)"
          :array_idx="hr_index"
          :address="parseInt(hr.address)"
          :description ="hr.description"
          :register_type ="hr.holding_registers_type_description"
          :holding_registers_id ="hr.holding_registers_id"
          :max="parseInt(hr.max)"
          :min="parseInt(hr.min)"
          :sensor_model ="hr.sensor_name"
          :value ="parseInt(hr.sensor_data_value)"
          :LUTS ="hr.last_update_ts"
          :origin ="hr.origin"
          :LUU ="parseInt(hr.last_update_user_id)"
          v-bind:key="hr.address"
          v-for="(hr,hr_index) in sensorHoldingRegistersList"
        />
      </table>
      <div
        class="d-flex align-items-center justify-content-center noItemsSpan"
        v-if="!hasItems"
      >
        No items found for selection
      </div>
    </div>
  </div>
</template>

<script>
  import { useStore } from "vuex";
  import { computed } from "vue";
  import SensorHoldingRegistersItem from "./sensor-holdingRegisters-item";
  import moment from 'moment'
  export default {
    name: "sensorHoldingRegistersList",
    components: {
      SensorHoldingRegistersItem,
    },
    props: {
      source: String,
      usage: String,
      showPlus: Boolean,
      showEraser: Boolean,
      canClick: Boolean,
      showRemoveFromUser: Boolean,
      showRemoveFromGateway: Boolean,
      status : String,
    },
    setup() {
      const store = useStore();
      const UpdateNewHoldingParameters = () => {
        store.dispatch("sensor/updateSensorNewHoldingRegisters", )
      };
      return {
        sensorHoldingRegistersList: computed(() => store.state.apgList.List['sensor_holdingRegisters']),
        UpdateNewHoldingParameters,
        hasItems: computed(() => {
          return true;
        }),
        readAll: () => {
          store.dispatch("sensor/readAllHoldingRegisters", {
            sensorId: store.state.apgList.List.sensor_info?.sensor_id,
            powerLine: store.state.apgList.List.sensor_attribute?.find((attr) => attr.attribute_name === "powerLine")?.attribute_value,
          });
        },
        holdingParmsExist: computed(() => {
          return store.state.apgList.List.sensor_holdingRegisters?.length > 0;
        }),
      };
    },
    methods: {
    moment: function () {
      return moment();
      }
    },
  };
</script>
<style scoped>
  .noItemsSpan {
    height: 200px;
    width: 100%;
    background-color: aliceblue;
  }
</style>