import { reactive } from "vue";
import { io } from "socket.io-client";

class Socket {
  constructor(wssUrl) {
    this.url = wssUrl;
    this.state = reactive({
      connected: false,
    });

    // connect
    this.socket = io(wssUrl, {
      secure: true,
    });

    // on connect
    this.socket.on("connect", () => {
      console.log('websocket connected');
      this.state.connected = true;
    });

    // on disconnect
    this.socket.on("disconnect", () => {
      console.log('websocket disconnected');
      this.state.connected = false;
    });

    this.loadUser();
  }

  registerListener(channel, callback) {
    console.log('Registered listener for channel: ', channel);
    this.socket.on(channel, (data) => {
      callback(data);
    });
  }

  sendData(channel, data) {
    console.log('Sending data to channel: ', channel);
    this.socket.emit(channel, {
      user: this.user,
      data: data,
    });
  }

  loadUser() {
    this.user = JSON.parse(localStorage.getItem("user"))?.user?.Auth;
    if (this.user) this.sendData('user_set');
  }

  resetSocket() {
    this.socket.disconnect();
    this.socket = null;
    this.socket = io(this.url, {
      secure: true,
    });
  }
}

// singleton; we only ever want one websocket connection
const URL = process.env.VUE_APP_API_URL || "http://localhost:7072";
const socketInstance = new Socket(URL);
export default socketInstance;
